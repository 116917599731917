import React from "react"
import antivirusIcon from "../../assets/images/sistem-antivirus.png"
import notifIcon from "../../assets/images/sistem-notificari.png"
import servIcon from "../../assets/images/servicii-web.png"
import lansIcon from "../../assets/images/lansare-rapida.png"

const DedicatedServers = () => {
  return (
    <section id="dedicated-servers-info">
      <div className="container-fluid">
        <div className="title-row mb-3">
          <h2 className="h3-title text-center mb-4">Descoperă beneficiile unui server hosting dedicat oferite de
            SiteBunker</h2>
          <p>La SiteBunker pachetele de server hosting dedicate pot include contra cost licențe și servicii
            suplimentare,
            astfel încât să te poți bucura de un timp de răspuns ultra performant.</p>
        </div>
        <div className="row">
          <div className="col-md-6 col-lg-3 dedicated-op">
            <img src={lansIcon}  loading="lazy"  alt="" />
            <h3 className="mb-3">Lansarea rapidă</h3>
            <p>Orice server hosting dedicat de la SiteBunker este lansat în maxim 24 de ore. Contactează echipa suport
              pentru detalii complete.</p>
          </div>
          <div className="col-md-6 col-lg-3 dedicated-op">
            <img src={notifIcon} loading="lazy"  alt="" />
            <h3 className="mb-3">Remote Access</h3>
            <p>Primești garantat acces la ILO dedicat pentru un management facil al server-ului (start/stop/restart/etc)
              în orice situație.</p>
          </div>
          <div className="col-md-6 col-lg-3 dedicated-op">
            <img src={antivirusIcon} loading="lazy"  alt="" />
            <h3 className="mb-3">Software Premium</h3>
            <p>Ai posibilitatea de a achiziționa licențe extra de cPanel, WHM, Cloudlinux, dedicate managementului de
              server hosting.</p>
          </div>
          <div className="col-md-6 col-lg-3 dedicated-op">
            <img src={servIcon} loading="lazy"  alt="" />
            <h3 className="mb-3">Suport non-stop</h3>
            <p>La SiteBunker ai suport tehnic asigurat pe bază de ticket/email. Telefonic ne poți contacta de luni pană
              vineri în intervalul 9.00 - 17.00.</p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default DedicatedServers
