import React from "react"
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import HostingPlanBlock from "../components/HostingPlans/HostingPlanBlock"
import InfoBlocks from "../components/Index/InfoBlocks"
import ClientFeedback from "../components/Index/ClientFeedback"
import Footer from "../components/App/Footer"
import DedicatedServers from "../components/HostingPlans/DedicatedServers"
import {Link} from "gatsby"
import BannerArea from "../components/Common/BannerArea"
import SEO from "../components/App/SEO"
import IntroHostingPlansParams from "../components/HostingPlans/IntroHostingPlansParams"
import IntroHostingBgWhite from "../components/HostingPlans/IntroHostingBgWhite";

const ServereDedicate = () => {
    return (
        <Layout>
            <SEO title="Servere Dedicate - Sitebunker.ro - Servicii de inchiriere server hosting"
                 description="Află detalii despre pachetele pentru servere dedicate SiteBunker.ro. Poți apela la serviciul de închiriere server hosting personalizat telefonic.."
            />
            <Navbar/>

            <IntroHostingBgWhite
                title={'Ai Server Hosting Dedicat cu trafic nelimitat și resurse premium'}
                subtitle={'Alege serverul potrivit nevoilor tale și beneficiază de suport 24/7, acces remote și uptime ridicat la nivel de TIER 3.'}
            />


            <div className="serv-dedic">

                <HostingPlanBlock
                    category="Servere dedicate"
                    splitTitle="yes"/>

                <BannerArea
                    pageTitle="Server Hosting Dedicat"
                    pageSubtitle="Procesoare puternice, stocare rapidă"
                    pageDescription="Acum este simplu să ai propriul server hosting dedicat. Descoperă mai jos ce beneficii includ pachetele pentru servere dedicate oferite de SiteBunker."
                    svgIllustration="server-dedicated"
                />
            </div>
            <div className="container-fluid">
                <div className="serv-contact">
                    <h3 className="mb-3">Ai nevoie de server hosting dedicat personalizat?</h3>
                    <Link to="/contact/" className="filled-button">
                        Contactează-ne
                    </Link>
                </div>
            </div>
            <DedicatedServers/>
            <div className="remove-top-padding">
                <InfoBlocks/>
            </div>
            <ClientFeedback
                title={"Află recenziile clienților SiteBunker aici:"}/>
            <Footer/>
        </Layout>
    )
}

export default ServereDedicate
